

jQuery(document).ready(function($) {

    // jquery code
    $(document).ready(function () {

    // Preloader
        $('.preloader-gif').fadeOut(); // will first fade out the loading animation 
        $('.slider-preloader').delay(150).fadeOut('slow'); // will fade out the white DIV that covers the website. 
        $('.carousel').delay(350).css({'overflow':'visible'});
   
    

        // owlCarousel
        //$('.owl-carousel').owlCarousel();
        $('.slide-fotos').owlCarousel({
            pagination: true,
            nav: true,
            loop: false,
            dots: true,
            margin: 20,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            mouseDrag: false,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1000: {
                    items: 4
                }
            }

        });



        $('.slide-principal').owlCarousel({
            pagination: true,
            nav: false,
            loop: true,
            dots: true,
            margin: 0,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }
        });

        $('.slide-single').owlCarousel({
            pagination: true,
            nav: true,
            loop: true,
            dots: false,
            margin: 0,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            mouseDrag: false,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }

        });

        $('.post-slide').owlCarousel({
            pagination: true,
            nav: true,
            loop: true,
            dots: true,
            margin: 0,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            mouseDrag: false,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }

        });


        $('.slide-projectes, .slide-header').owlCarousel({
            pagination: true,
            nav: true,
            loop: true,
            dots: false,
            touchDrag: false,
            mouseDrag: false,
            margin: 0,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            //animateIn: 'fadeIn',
            //animateOut: 'fadeOut',
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }

        });


        $('.slide-actividades, .slide-servicios, .slide-noticias').owlCarousel({
            pagination: true,
            nav: false,
            loop: false,
            dots: true,
            margin: 20,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1000: {
                    items: 3
                },
                1200: {
                    items: 3
                },
            }

        });


        $('.slide-products').owlCarousel({
            pagination: true,
            nav: true,
            loop: false,
            dots: true,
            margin: 20,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1000: {
                    items: 3
                },
                1200: {
                    items: 4
                },
            }

        });

        

        $(".owl-prev").html('<span class="arrow-left-b"></span>');
        $(".owl-next").html('<span class="arrow-right-b"></span>');

 

       // Anchor scroll
        // jQuery for page scrolling feature - requires jQuery Easing plugin
        $('a.anchor').bind('click', function(event) {
            var $anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: ($($anchor.attr('href')).offset().top - 0)
            }, 1250, 'easeInOutExpo');

            event.preventDefault();
        });



        // Select all links with hashes
        $('.menu-item a[href*="#"]') .not('[href="#"]') .not('[href="#0"]') .click(function (event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                location.hostname == this.hostname
                ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({scrollTop: target.offset().top}, 1000, function () {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } 
                        else {
                            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });


       // Empresas
        $('.colapsar').click(function() {
      

            
            $('.colapsar').removeClass('current');
            $(this).addClass('current');
            $('.box').removeClass('current');
            var $target = $(this).attr('href');
            $($target).addClass('current');
            return false;
        });

        $('.slide-projectes .owl-next').click(function() {
            $('.empresas .box').removeClass('current');
         
            return false;
        });

        $('.slide-projectes .owl-prev').click(function() {
            $('.empresas .box').removeClass('current');
         
            return false;
        });

       
        // Listen to owl events:
        $('.slide-projectes').on('changed.owl.carousel', function() {
            $('.empresas .box').removeClass('current');
         
            return false;
        });

        


        //$('.empresas .box:first').addClass('current');
        //$('.empresas .colapsar:first').addClass('current');


        // Empresas adress
        $('.empresas-adress .box:first').addClass('current');
        $('.empresas-adress .colapsar:first').addClass('current');

        // Carousel
        $('.carousel .carousel-item:first').addClass('active');

       


        // Animate elements when scrolled into view with native JavaScript
        var animateHTML = function() {
            var elems;
            var windowHeight;

            function init() {
                elems = document.querySelectorAll('.hidden');
                windowHeight = window.innerHeight;
                addEventHandlers();
                checkPosition();
            }

            function addEventHandlers() {
                window.addEventListener('scroll', checkPosition);
                window.addEventListener('resize', init);
            }

            function checkPosition() {
                for (var i = 0; i < elems.length; i++) {
                    var positionFromTop = elems[i].getBoundingClientRect().top;
                    if (positionFromTop - windowHeight <= 0) {
                        elems[i].className = elems[i].className.replace(
                            'hidden',
                            'fade-in-element'
                        );
                    }
                }
            }
            return {
                init: init
            };
        };
        animateHTML().init();
        

        // magnificPopup for .image-link
        $('.gallery-item a').magnificPopup({ type: 'image' });

        $('.image-link').magnificPopup({ type: 'image' });


        $('.popup-gallery, .slide-single').magnificPopup({
            delegate: '.owl-item:not(.cloned) a',
            type: 'image',
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
                beforeOpen: function () {
                    // just a hack that adds mfp-anim class to markup 
                    this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                    this.st.mainClass = this.st.el.attr('data-effect');
                }
            },
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title') + '<small></small>';
                }
            }
        });



        // Popup
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,

            fixedContentPos: false
        });


        // Cambiar clases botones
  

        $('.woocommerce-MyAccount-content .woocommerce-Input').addClass('form-control');
        

        

        $('.single_add_to_cart_button').addClass('btn btn-primary');
        $('.single_add_to_cart_button').removeClass('button');
        

        $('.wc-forward').addClass('btn btn-primary');
        $('.wc-forward').removeClass('button');


        $('a.checkout-button ').addClass('btn');
        $('a.checkout-button ').removeClass('button');


        $('input[type="submit"]').addClass('btn');
        $('input[type="submit"]').removeClass('button');


        // the selector for gallery item
        $('.woocommerce-product-gallery__wrapper').each(function() { // the containers for all your galleries
            $(this).magnificPopup({
                delegate: 'a', // the selector for gallery item
                type: 'image',
                gallery: {
                enabled:true
                }
            });
        });
        

        // Contact
        $('.wpcf7-form .wpcf7-text').addClass('form-control');
        $('.wpcf7-textarea').addClass('form-control');
        $('.wpcf7-date').addClass('form-control');
        $('.wpcf7-submit').addClass('btn btn-primary');
        //$('.input-xlarge').addClass('form-control');



        // Videos
        fluidvids.init({
            selector: ['iframe', 'object'], // runs querySelectorAll()
            players: ['www.youtube.com', 'player.vimeo.com'] // players to support
        });
        


    });


});
